import Countdown from "./components/Countdown";
import PrivacyPolicy from "./components/PrivacyPage";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/" element={<Countdown />} />
          <Route exact path="/privacy" element={<PrivacyPolicy />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
